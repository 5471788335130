import { Box, Center, Flex } from '@chakra-ui/react';
import Card from 'components/card/Card';
import { CSSProperties, useRef, useState } from 'react';
import * as XLSX from 'xlsx';

const styles = {
  zone: {
    alignItems: 'center',
    border: `2px dashed grey`,
    borderRadius: 20,
    display: 'flex',
    flexDirection: 'column',
    width: 400,
    height: 200,
    justifyContent: 'center',
    padding: 20,
    cursor: 'pointer',
    textAlign: 'center',
  } as CSSProperties,
  file: {
    background: 'linear-gradient(to bottom, #EEE, #DDD)',
    borderRadius: 20,
    display: 'flex',
    width: 120,
    position: 'relative',
    zIndex: 10,
    flexDirection: 'column',
    justifyContent: 'center',
  } as CSSProperties,
  info: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: 10,
    paddingRight: 10,
  } as CSSProperties,
  size: {
    borderRadius: 3,
    marginBottom: '0.5em',
    justifyContent: 'center',
    display: 'flex',
  } as CSSProperties,
  name: {
    borderRadius: 3,
    fontSize: 12,
    marginBottom: '0.5em',
  } as CSSProperties,
  progressBar: {
    width: '100%',
    paddingLeft: 10,
    paddingRight: 10,
  } as CSSProperties,
  zoneHover: {
    borderColor: 'grey',
  } as CSSProperties,
  default: {
    borderColor: 'grey',
  } as CSSProperties,
  remove: {
    height: 23,
    position: 'absolute',
    right: 6,
    top: 6,
    width: 23,
  } as CSSProperties,
};

export default function OfflineUploadFile(props: {
  used?: number;
  total?: number;
  [x: string]: any,
  onFiles?: (files: string) => void,
  onData?: (files: Array<any>) => void
  onLoading?: (loading: boolean) => void,
}) {
  const { used, total, onFiles, onData, ...rest } = props;
  const [zoneHover, setZoneHover] = useState(false);
  const fileInputRef = useRef<HTMLInputElement | null>(null); // Usar o useRef para o input

  const handleFileUpload = (file: File) => {
    props.onLoading(true);
    const reader = new FileReader();
    reader.onload = (event) => {
      const data = new Uint8Array(event.target?.result as ArrayBuffer);
      const workbook = XLSX.read(data, { type: 'array' });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1, raw: false });
      onData?.(jsonData);
    };

    reader.readAsArrayBuffer(file);
  };

  return (
    <Card {...rest} mb='20px' alignItems='center' p='20px'>
      <Flex flexDirection={{ base: 'column', md: 'row' }}>
        <Center>
          <Box
            maxW={{ base: '100%', md: '50%' }}
            style={Object.assign({}, styles.zone, zoneHover && styles.zoneHover)}
            onClick={() => {
              if (fileInputRef.current) {
                fileInputRef.current.click(); // Abre o seletor de arquivos
              }
            }}
            onDragOver={(event) => {
              event.preventDefault();
              setZoneHover(true);
            }}
            onDragLeave={(event) => {
              event.preventDefault();
              setZoneHover(false);
            }}
            onDrop={(event) => {
              event.preventDefault();
              setZoneHover(false);
              const file = event.dataTransfer.files[0];
              if (file && file.name.endsWith('.xlsx')) {
                handleFileUpload(file);
                onFiles?.(file.name);
              }
            }}
          >
            Selecione arquivo XLSX aqui para importar a base
          </Box>
          <input
            type="file"
            accept=".xlsx"
            ref={fileInputRef} // Conecta o input ao useRef
            style={{ display: 'none' }} // Esconde o input
            onChange={(e) => {
              const file = e.target.files?.[0];
              if (file && file.name.endsWith('.xlsx')) {
                handleFileUpload(file);
                onFiles?.(file.name);
              }
            }}
          />
        </Center>
      </Flex>
    </Card>
  );
}
