import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  HStack,
  SimpleGrid,
  Spacer,
} from '@chakra-ui/react';
import React, { useEffect } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import {
  IntegrationAuth,
  IntegrationAuthType,
  IntegrationAuthTypeTranslation,
} from '../../models/integrationAuth.model';
import {
  createIntegration,
  editIntegration,
  removeIntegration,
} from '../../store/features/integration/integration.slice';
import { CreateIntegrationAuthDto } from '../../services/api/integrationAuth/integrationAuth.dto.api';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { showToast } from '../../services/toast.service';
import { InputText } from '../inputText/inputText.component';
import { InputSelect } from '../inputSelect/inputSelect.component';
import { RootState } from '../../store/store';
import ConfirmationButton from '../buttons/RemoveConfirmation';
import { BiTrashAlt } from 'react-icons/bi';
import { BankType, BankTypeName } from '../../models/robot.model';
import { TypingRobotsBanks } from '../../models/typingRobot.model';

interface Imodal {
  integration: IntegrationAuth | undefined;
  isOpen: boolean;
  onClose: (updated: boolean) => void;
  selectedType: IntegrationAuthType;
}

export function ModalIntegration({ integration, isOpen, onClose, selectedType }: Imodal) {
  const { loadingUpdate, error } = useAppSelector((state: RootState) => state.integration);
  const dispatch = useAppDispatch();
  const { handleSubmit, handleChange, values, resetForm, errors, setFieldValue, dirty, setValues, touched } = useFormik({
    initialValues: {
      id: integration?.id,
      name: integration?.name || '',
      login: integration?.login,
      password: integration?.password,
      extraAuth: integration?.extraAuth,
      type: integration?.type || selectedType,
      bank: integration?.bank || BankType.FACTA,
    },
    validationSchema: yup.object().shape({
      name: yup.string().required('Campo obrigatório'),
      type: yup.string().required('Campo obrigatório'),
      login: yup.string().required('Campo obrigatório'),
      password: yup.string().required('Campo obrigatório'),
      extraAuth: selectedType === IntegrationAuthType.MEU_CORBAN ? yup.string().required('Campo obrigatório') : null,
    }),
    onSubmit: async (values) => {
      if (values.bank === BankType.V8 && !values.extraAuth) {
        showToast('error', 'Informe o Cliente API!');
        return;
      }
      console.log('values', values);
      if (!integration?.id) {
        const response: any = await dispatch(createIntegration({
          name: values.name,
          login: values.login,
          password: values.password,
          type: values.type,
          bank: values.bank,
          extraAuth: values.extraAuth,
        }));
        if (response.error) {
          showToast('error', response.error.message);
        } else {
          onCreated();
        }
      } else {
        const response: any = await dispatch(editIntegration({ id: integration.id, data: { ...values } as CreateIntegrationAuthDto}));
        if (response.error) {
          showToast('error', response.error.message);
        } else if (response.payload) {
          onCreated();
        }
      }
    },
  });

  useEffect(() => {
    resetForm();
    setValues({...integration});
  }, [integration]);

  useEffect(() => {
    if (error) {
      showToast('error', error);
    }
  }, [error]);

  useEffect(() => {
    if (isOpen) {
      setFieldValue('type', selectedType);
    }
  }, [isOpen]);

  async function remove() {
    const response = await dispatch(removeIntegration({
      id: integration.id,
    }));
    if (response.payload) {
      showToast('success', 'Removido!');
      onClose(true);
    } else {
      showToast('error', error);
    }
  }

  function closeModal(updated: boolean) {
    resetForm();
    onClose(updated);
  }

  function onCreated() {
    showToast('success', 'Sucesso!');
    closeModal(true);
  }

  return (
    <Drawer
      size={'xl'}
      isOpen={isOpen}
      placement='right'
      onClose={() => closeModal(false)}
      closeOnEsc={false}
    >
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader>
          {integration?.login || 'Nova integração'}
        </DrawerHeader>
        <DrawerBody>
          <Box borderWidth='1px' borderRadius='lg' p={5} overflow='hidden' mb={5}>
            <SimpleGrid columns={1} spacing={{ base: '20px', xl: '20px' }}>
              <InputSelect
                key={'type-select'}
                label='Tipo'
                placeholder='Tipo'
                disabled={true}
                value={values?.type}
                onChange={handleChange('type')}
                error={errors.type}
                options={Object.values(IntegrationAuthType).map(v => {
                  return {
                    label: IntegrationAuthTypeTranslation[v],
                    value: v,
                  };
                })}
              />
              <InputText
                key={'name'}
                label='Nome'
                value={values?.name}
                onChange={handleChange('name')}
                error={errors.name}
              />
              {selectedType === IntegrationAuthType.BANK ? <InputSelect
                key={'bank-select'}
                label='Banco'
                placeholder='Banco'
                value={values?.bank}
                onChange={handleChange('bank')}
                error={errors.bank}
                options={Object.values(BankType).filter(b => TypingRobotsBanks.includes(b)).map(v => {
                  return {
                    label: BankTypeName[v],
                    value: v,
                  };
                })}
              /> : null}
              {selectedType === IntegrationAuthType.MEU_CORBAN && <InputText
                key={'extraAuth'}
                label='Empresa'
                value={values?.extraAuth}
                onChange={handleChange('extraAuth')}
                error={errors.extraAuth}
              />}
              <InputText
                key={'login'}
                label='Login'
                value={values?.login}
                onChange={handleChange('login')}
                error={errors.login}
              />
              <InputText
                key={'password'}
                label='Senha'
                value={values?.password}
                onChange={handleChange('password')}
                error={errors.password}
              />
              {selectedType === IntegrationAuthType.BANK && values?.bank === BankType.V8 ? <InputText
                key={'extraAuth'}
                label='Cliente API'
                value={values?.extraAuth}
                onChange={handleChange('extraAuth')}
                error={errors.extraAuth}
              />: null}
            </SimpleGrid>
          </Box>
        </DrawerBody>
        <DrawerFooter>
          <Flex w={'100%'} gap={2}>
            {integration?.id ? <ConfirmationButton onConfirm={remove}>
              <Button rightIcon={<BiTrashAlt />} colorScheme='red' size={'md'} isLoading={loadingUpdate}>
                Remover
              </Button>
            </ConfirmationButton> : null}
            <Spacer />
            <HStack>
              <Button isDisabled={loadingUpdate} colorScheme='gray' mr={3} onClick={() => onClose(false)}>
                Fechar
              </Button>
              <Spacer />
              <Button isLoading={loadingUpdate} colorScheme='blue' mr={3} onClick={() => handleSubmit()}>
                Salvar
              </Button>
            </HStack>
          </Flex>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
}
