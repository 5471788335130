import { BankType } from './robot.model';

export enum IntegrationAuthType {
  MEU_CORBAN = 'MEU_CORBAN',
  BANK = 'BANK',
  HUGGY = 'HUGGY',
}

export const IntegrationAuthTypeTranslation: {[key in IntegrationAuthType]: string} = {
  [IntegrationAuthType.MEU_CORBAN]: 'Meu Corban',
  [IntegrationAuthType.BANK]: 'Banco',
  [IntegrationAuthType.HUGGY]: 'Huggy',
}

export type IntegrationAuth = {
  id: string
  name: string;
  type: IntegrationAuthType;
  bank: BankType;
  apiKey: string;
  extraAuth: string;
  login: string
  password: string
}

export interface BankTable {
  name: string;
  code: string;
  bank: string;
}
