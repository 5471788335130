import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { wesendApi } from '../../../services/api/wesend.api';
import { PaginatedMetaData } from '../../../services/api/lead/lead.dto.wesendapi';
import { getCompanySubscription } from '../company/company.slice';
import { CreateIntegrationAuthDto } from '../../../services/api/integrationAuth/integrationAuth.dto.api';
import { BankTable, IntegrationAuth } from '../../../models/integrationAuth.model';

export interface IntegrationState {
  integrations: IntegrationAuth[];
  loading: boolean;
  loadingUpdate: boolean;
  error: string | null;
  pagination: PaginatedMetaData | undefined;
  bankTables: BankTable[];
}

const initialState: IntegrationState = {
  integrations: [],
  loading: false,
  loadingUpdate: false,
  error: null,
  bankTables: [],
  pagination: {
    total: 0,
    lastPage: 0,
    currentPage: 0,
    perPage: 20,
    prev: 0,
    next: 0,
  },
};

export const getIntegrations = createAsyncThunk<{ integrations: Array<IntegrationAuth>, pagination: PaginatedMetaData },{ page: number }>(
  'integrationAuth/list',
  async (params) => {
    const channels = await wesendApi.integration.list({
      page: params.page,
    });
    return { integrations: channels.data, pagination: channels.meta };
  });

export const removeIntegration = createAsyncThunk<{ success: boolean },{ id: string }>(
  'integrationAuth/remove',
  async (params, {dispatch, getState}) => {
    await wesendApi.integration.delete(params.id);
    dispatch(getCompanySubscription());
    return { success: true };
  },
);

export const createIntegration = createAsyncThunk<IntegrationAuth, CreateIntegrationAuthDto>(
  'integrationAuth/create',
  async (channel, {dispatch, getState}) => {
    return await wesendApi.integration.create(channel);
  },
);

export const editIntegration = createAsyncThunk<IntegrationAuth, {id: string, data: CreateIntegrationAuthDto}>(
  'integrationAuth/edit',
  async ({ id, data }, {dispatch, getState}) => {
    return await wesendApi.integration.edit(id, data);
  },
);

export const getTables = createAsyncThunk<Array<BankTable>>(
  'integrationAuth/getTables',
  async (params) => {
    return await wesendApi.integration.getTables();
  });

export const integrationSlice = createSlice({
  name: 'IntegrationAuth',
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder
      .addCase(getIntegrations.pending, (state) => {
        state.loading = true;
        state.error = undefined;
      })
      .addCase(getIntegrations.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getIntegrations.fulfilled, (state, action) => {
        state.error = null;
        state.integrations = action.payload.integrations;
        state.loading = false;
        state.pagination = action.payload.pagination;
      })
      .addCase(editIntegration.pending, (state) => {
        state.loadingUpdate = true;
      })
      .addCase(editIntegration.fulfilled, (state, action) => {
        state.error = null;
        state.loadingUpdate = false;
      })
      .addCase(editIntegration.rejected, (state, action) => {
        state.loadingUpdate = false;
        state.error = action.error.message;
      })
      .addCase(removeIntegration.pending, (state) => {
        state.loadingUpdate = true;
      })
      .addCase(removeIntegration.fulfilled, (state, action) => {
        state.error = null;
        state.loadingUpdate = false;
      })
      .addCase(removeIntegration.rejected, (state, action) => {
        state.loadingUpdate = false;
        state.error = action.error.message;
      })
      .addCase(createIntegration.pending, (state) => {
        state.loadingUpdate = true;
      })
      .addCase(createIntegration.fulfilled, (state, action) => {
        state.error = null;
        state.loadingUpdate = false;
      })
      .addCase(createIntegration.rejected, (state, action) => {
        state.loadingUpdate = false;
        state.error = action.error.message;
      })
      .addCase(getTables.fulfilled, (state, action) => {
        state.error = null;
        state.bankTables = action.payload;
      })
  },
});
