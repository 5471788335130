import { LeadSimulationStatus } from '../../../../models/lead.model';
import { isValid, parse } from 'date-fns';

export const OfflineImportMap: {
  [key: string]: { column: string; defaultValue: any };
} = {
  cpf: { column: 'CPF', defaultValue: null },
  facta_offline_saldo: { column: 'facta_offline_saldo', defaultValue: null },
  facta_offline_lib: { column: 'facta_offline_lib', defaultValue: null },
  facta_offline_message: { column: 'facta_offline_message', defaultValue: null },
};

export class ImportOfflineSimulationDto {
  cpf: string;
  status: LeadSimulationStatus;
  credit: number;
  statusValidity: Date;
}

export function offlineGetCredit(value: string): number {
  return 0;
}

export function offlineGetStatus(value: string): LeadSimulationStatus {
  if (value === 'Não Autorizado') {
    return LeadSimulationStatus.WITHOUT_PERMISSION;
  }
  if (value === 'Autorizado') {
    return LeadSimulationStatus.NO_CREDIT;
  }
  if (value === 'Indisponível') {
    return LeadSimulationStatus.NO_CREDIT;
  }
  return LeadSimulationStatus.NEW;
}

export function offlineGetValidity(value: string): Date {
  try {
    if (value.includes('CPF autorizado até')) {
      const dateStr = value.split(' até ')[1];
      const date = parse(dateStr, 'dd/MM/yyyy', new Date());
      if (isValid(date)) {
        return date;
      }
    }
  } catch (e) {
    return null;
  }
  return null;
}
