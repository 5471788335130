import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  Center,
  CircularProgress,
  Flex,
  HStack,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Progress,
  Spacer,
  Switch,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import Upload from './Upload';
import React, { useEffect, useState } from 'react';
import { CreateLeadDto, LeadSimulationStatus } from '../../../../models/lead.model';
import {
  clearResult,
  getImportStatus,
  importLeads,
  importLeadsOfflineSimulation,
} from '../../../../store/features/leads/leads.api';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { RootState } from '../../../../store/store';
import MiniStatistics from '../../../../components/card/MiniStatistics';
import IconBox from '../../../../components/icons/IconBox';
import { MdPerson, MdPersonAdd } from 'react-icons/md';
import { isCPFValid, isPhoneValid } from '../../../../helpers/lead.helpers';
import { InputSelect } from '../../../../components/inputSelect/inputSelect.component';
import { BankType, BankTypeName } from '../../../../models/robot.model';
import { FaDownload } from 'react-icons/fa6';
import { OptionItemType } from '../../../../components/filter/filter.constants';
import { createLeadTag } from '../../../../store/features/leadTag/leadTag.slice';
import { LeadTagSelector } from '../../../../components/leadTagSelector/leadTagSelector';
import { LeadTag } from '../../../../models/leadTag.model';
import OfflineUploadFile from './OfflineUploadFile';
import {
  ImportOfflineSimulationDto,
  offlineGetCredit,
  offlineGetStatus,
  offlineGetValidity,
  OfflineImportMap,
} from './OfflineImport.dto';

type tabsType = 'default' | 'offlineBalance';
const tabsTypes: Array<tabsType> = ['default', 'offlineBalance'];

export default function ImportButton() {

  const initialRef = React.useRef(null);
  const finalRef = React.useRef(null);
  const {
    importPercent,
    importing,
    loadingExistingNewLeads,
    result,
    error,
  } = useAppSelector((state: RootState) => state.leads);
  const dispatch = useAppDispatch();
  const [file, setFile] = useState<string>(null);
  const [data, setData] = useState<Array<any>>([]);
  const [fileOffline, setFileOffline] = useState<string>(null);
  const [dataOffline, setDataOffline] = useState<Array<any>>([]);
  const [fileLeadsOffline, setFileLeadsOffline] = useState<Array<ImportOfflineSimulationDto>>([]);
  const [fileLeads, setFileLeads] = useState<Array<CreateLeadDto>>([]);
  const [importId, setImportId] = useState<string>(null);
  const [hasAnyCredit, setHasAnyCredit] = useState<boolean>(false);
  const [selectedBank, setSelectecBank] = useState<BankType>(null);
  const { isOpen: resultIsOpen, onOpen: resultOnOpen, onClose: resultOnClose } = useDisclosure();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [ignoreNewPhone, setIgnoreNewPhone] = useState<boolean>(false);
  const [ignoreNewName, setIgnoreNewName] = useState<boolean>(false);
  const [selectedTag, setSelectedTag] = useState<OptionItemType>(null);
  const { tags, loading: loadingTags } = useAppSelector((state: RootState) => state.leadTag);
  const [selectedType, setSelectedType] = useState<tabsType>('default');
  const [missingColumns, setMissingColumns] = useState<Array<string>>([]);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    const missing: string[] = [];
    if (dataOffline && dataOffline.length) {
      const firstRow = dataOffline[0]; // Primeira linha como referência
      Object.values(OfflineImportMap).forEach(({ column, defaultValue }) => {
        if (!(firstRow.includes(column)) && !defaultValue) {
          missing.push(column);
        }
      });
    }
    setMissingColumns(missing);
  }, [dataOffline]);

  useEffect(() => {
    if (dataOffline && dataOffline.length) {
      const firstRow = dataOffline[0];
      const indices: Record<string, number> = {};

      Object.entries(OfflineImportMap).forEach(([key, { column }]) => {
        const index = firstRow.findIndex((header: string) => header?.toLowerCase().replace(/[^a-zA-Z0-9 ]/g, "") === column.toLowerCase().replace(/[^a-zA-Z0-9 ]/g, ""));
        if (index !== -1) {
          indices[key] = index;
        }
      });

      const newLeads: Array<ImportOfflineSimulationDto> = [];

      dataOffline.slice(1).forEach((row) => {
        const lead = new ImportOfflineSimulationDto();
        const cpf = isCPFValid(row[indices['cpf']] || '');

        if (cpf && row[indices['facta_offline_saldo']]) {
          Object.entries(OfflineImportMap).forEach(([key, { defaultValue }]) => {
            const columnKey = key;
            const value = row[indices[key]];

            switch (columnKey) {
              case 'cpf':
                lead[columnKey] = cpf;
                break;
              case 'facta_offline_saldo':
                lead.credit = offlineGetCredit(value);
                break;
              case 'facta_offline_lib':
                lead.status = offlineGetStatus(value);
                break;
              case 'facta_offline_message':
                lead.statusValidity = offlineGetValidity(value);
                break;
              default:
                // @ts-ignore
                lead[columnKey] = value || defaultValue;
                break;
            }
          });

          if (lead.status !== LeadSimulationStatus.NEW) {
            newLeads.push(lead);
          }
        }
      });

      console.log(newLeads); // Para verificar os leads processados
      setFileLeadsOffline(newLeads);
      setLoading(false);
    }
  }, [dataOffline]);

  useEffect(() => {
    if (data && data.length) {
      let hasAnyCreditControl = false;
      const newLeads: Array<CreateLeadDto> = [];
      data.forEach(l => {
        const lead = new CreateLeadDto();
        const cpf = isCPFValid(l[0] || '');
        const phoneNumber = isPhoneValid(l[2] || '');
        const name = String(l[1] || '').trim();
        const credit = l[3] ? parseFloat(l[3].trim().replace('.', '').replace(',', '.')) : 0;
        if (cpf) {
          lead.name = name;
          lead.phone = phoneNumber || '';
          lead.cpf = cpf;
          try {
            lead.credit = isNaN(credit) ? 0 : credit;
            if (lead.credit) {
              hasAnyCreditControl = true;
            }
          } catch (e) {
            lead.credit = 0;
          }
          newLeads.push(lead);
        }
      });
      setFileLeads(newLeads);
      setHasAnyCredit(hasAnyCreditControl);
    }
  }, [data]);

  useEffect(() => {
    const leads = [...fileLeads];
    for (const lead of leads) {
      lead.bank = selectedBank;
    }
    setFileLeads(leads);
  }, [selectedBank]);

  useEffect(() => {
    if (result) {
     reset();
    }
  }, [result]);

  useEffect(() => {
    if (isOpen) {
      setSelectedTag(null);
      setIgnoreNewName(false);
      setIgnoreNewPhone(false);
    }
  }, [isOpen]);

  function reset() {
    setData([]);
    setFile(null);
    setFileLeads([]);
    setDataOffline([]);
    setFileOffline(null);
    setFileLeadsOffline([]);
    resultOnOpen();
    setImportId(null);
    setSelectecBank(null);
    setHasAnyCredit(false);
    setSelectedTag(null);
    setIgnoreNewName(false);
    setIgnoreNewPhone(false);
  }

  useEffect(() => {
    let importStatusInterval: string | number | NodeJS.Timeout;
    if (importId) {
      importStatusInterval = setInterval(() => {
        dispatch(getImportStatus(importId));
      }, 1000);
    } else {
      clearInterval(importStatusInterval);
    }
    return () => clearInterval(importStatusInterval);
  }, [importId]);

  function closeSuccess() {
    dispatch(clearResult());
    resultOnClose();
    onClose();
  }

  async function importLeadsToDatabase() {
    if (selectedType === 'default') {
      const response = await dispatch(importLeads({
        ignoreNewName,
        ignoreNewPhone,
        tagId: selectedTag ? selectedTag.value : null,
        leads: fileLeads,
      }));
      if (response.payload) {
        setImportId(String(response.payload));
      }
      return;
    }
    if (selectedType === 'offlineBalance') {
      const response = await dispatch(importLeadsOfflineSimulation(fileLeadsOffline));
      if (response.payload) {
        setImportId(String(response.payload));
      }
      return;
    }
  }

  async function createNewTag(name: string) {
    await dispatch(createLeadTag({
      name,
    }));
  }

  return (
    <>
      <Button mb={2} mr={3} rightIcon={<FaDownload />} colorScheme='blue' variant='link' onClick={onOpen}>
        Importar
      </Button>
      <Modal
        initialFocusRef={initialRef}
        finalFocusRef={finalRef}
        isOpen={isOpen}
        onClose={onClose}
        size={'xl'}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Importar leads</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <Tabs onChange={(index) => setSelectedType(tabsTypes[index])}>
              <TabList>
                <Tab>Padrão</Tab>
                <Tab>Consultas Offline</Tab>
              </TabList>
              <TabPanels>
                <TabPanel>
                  <Box pt={2}>
                    <Modal
                      isCentered
                      onClose={resultOnClose}
                      isOpen={resultIsOpen}
                      motionPreset='slideInBottom'
                    >
                      <ModalOverlay />
                      <ModalContent>
                        <ModalHeader>Importação concluída!</ModalHeader>
                        <ModalCloseButton />
                        <ModalBody>
                          {result?.created ? <Alert status='success'>
                            <AlertIcon />
                            Criados: {result?.created}
                          </Alert> : null}
                          {result?.updated ? <Alert status='info'>
                            <AlertIcon />
                            Atualizados: {result?.updated}
                          </Alert> : null}
                          {result?.error ? <Alert status='error'>
                            <AlertIcon />
                            Falhas: {result?.error}
                          </Alert> : null}
                          {result?.ignored ? <Alert status='warning'>
                            <AlertIcon />
                            Ignorados (repetidos): {result?.ignored}
                          </Alert> : null}
                        </ModalBody>
                        <ModalFooter>
                          <Button colorScheme='blue' mr={3} onClick={closeSuccess}>
                            OK
                          </Button>
                        </ModalFooter>
                      </ModalContent>
                    </Modal>
                    {error && <Box bg='tomato' w='100%' p={4} color='white'>{error}</Box>}
                    <Upload
                      onFiles={(f) => setFile(f)}
                      onData={(data) => setData(data)}
                    />
                    {data.length ? <HStack>
                      <MiniStatistics
                        startContent={
                          <IconBox
                            w='56px'
                            h='56px'
                            bg={'secondaryGray.300'}
                            icon={<Icon w='32px' h='32px' as={MdPerson} color={'brand.500'} />}
                          />
                        }
                        name='Leads do arquivo'
                        value={data.length}
                      />
                      <MiniStatistics
                        startContent={
                          loadingExistingNewLeads ?
                            <CircularProgress isIndeterminate={true} /> :
                            <IconBox
                              w='56px'
                              h='56px'
                              bg={'brand.500'}
                              icon={<Icon w='32px' h='32px' as={MdPersonAdd} color={'secondaryGray.300'} />}
                            />
                        }
                        name='Leads válidos'
                        value={fileLeads.length}
                      />
                    </HStack> : null}
                    {hasAnyCredit ? <Box mb={5}>
                      <InputSelect
                        key={'bank-select'}
                        label='Banco'
                        placeholder='Selecione'
                        value={selectedBank}
                        labelWidth={50}
                        onChange={(value) => setSelectecBank(value as BankType)}
                        error={hasAnyCredit && !selectedBank ? 'Há leads com créditos, selecione o banco' : ''}
                        options={Object.values(BankType).map(v => {
                          return {
                            label: BankTypeName[v],
                            value: v,
                          };
                        })}
                      />
                    </Box> : null}
                    <VStack spacing={2} mt={5} alignItems={'left'}>
                      <HStack>
                        <Switch
                          size='lg'
                          isChecked={ignoreNewPhone}
                          onChange={(e) => {
                            setIgnoreNewPhone(e.target.checked);
                          }}
                        />
                        <Text as={'b'}>Se o lead existir, NÃO atualizar TELEFONE</Text>
                      </HStack>
                      <HStack>
                        <Switch
                          size='lg'
                          isChecked={ignoreNewName}
                          onChange={(e) => {
                            setIgnoreNewName(e.target.checked);
                          }}
                        />
                        <Text as={'b'}>Se o lead existir, NÃO atualizar atualizar NOME</Text>
                      </HStack>
                    </VStack>
                    <HStack alignItems={'center'} mt={5}>
                      <Text as={'b'} textAlign={'left'}>Tag:</Text>
                      <LeadTagSelector
                        options={tags.map((t: LeadTag) => {
                          return {
                            label: t.name,
                            value: t.id,
                            colorScheme: 'blue',
                          };
                        })}
                        label={'asd'}
                        value={selectedTag}
                        onChange={(v) => setSelectedTag(v)}
                        onNewTag={(n) => createNewTag(n)}
                      />
                    </HStack>
                  </Box>
                </TabPanel>
                <TabPanel>
                  <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
                    <OfflineUploadFile
                      onFiles={(f) => setFileOffline(f)}
                      onData={(data) => setDataOffline(data)}
                      onLoading={(b) => setLoading(b)}
                    />
                    {loading ? <Progress size='xs' width={'100%'} isIndeterminate />: null}
                    {missingColumns.length ? <Alert status='error'>
                      <AlertIcon />
                      <Box>
                        <AlertTitle>Estão faltando as seguintes informações:</AlertTitle>
                        <AlertDescription>
                          {missingColumns.join(', ')}
                        </AlertDescription>
                      </Box>
                    </Alert> : null}
                    {!loading && dataOffline.length ? <HStack mb='20px'>
                      <MiniStatistics
                        startContent={
                          <IconBox
                            w='56px'
                            h='56px'
                            bg={'secondaryGray.300'}
                            icon={<Icon w='32px' h='32px' as={MdPerson} color={'brand.500'} />}
                          />
                        }
                        name='Linhas do arquivo'
                        value={dataOffline.length}
                      />
                      <MiniStatistics
                        startContent={
                          <IconBox
                            w='56px'
                            h='56px'
                            bg={'brand.500'}
                            icon={<Icon w='32px' h='32px' as={MdPersonAdd} color={'secondaryGray.300'} />}
                          />
                        }
                        name='Leads válidos'
                        value={fileLeadsOffline.length}
                      />
                    </HStack> : null}
                  </Box>
                </TabPanel>
              </TabPanels>
            </Tabs>
          </ModalBody>
          <ModalFooter>
            <Flex gap={3}>
              {importing ? <Center>
                <Progress colorScheme='blue' h='10px' w={'200px'}
                          value={importPercent} />
                <Text ml={3}>{importPercent}%</Text>
              </Center> : null}
              <Spacer />
              <Button onClick={onClose}>Cancelar</Button>
              <Button isDisabled={(selectedType === 'default' && fileLeads.length === 0) || (fileLeadsOffline.length === 0 && selectedType === 'offlineBalance') || (hasAnyCredit && !selectedBank)}
                      onClick={importLeadsToDatabase} isLoading={importing} size='md' colorScheme='blue'>
                Importar
              </Button>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
