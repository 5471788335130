import {
  Avatar,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Flex,
  HStack,
  Link,
  Table,
  Tag,
  TagLabel,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  VStack,
} from '@chakra-ui/react';
import { createColumnHelper, flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import Card from 'components/card/Card';
import * as React from 'react';
import { useEffect, useState } from 'react';
import {
  Lead,
  LeadMessageStatus,
  LeadProposalStatus,
  messageStatusName,
  proposalStatusName,
  simulationStatusName,
  statusColor,
} from '../../../models/lead.model';
import { formatCPF, formatPhoneNumber, formatToCurrency, toDate } from '../../../helpers/firebase.helpers';
import { ArrowBackIcon, ArrowForwardIcon } from '@chakra-ui/icons';
import { HSeparator } from '../../../components/separator/Separator';
import { PaginatedMetaData } from '../../../services/api/lead/lead.dto.wesendapi';
import { LastSimulation } from '../../../models/simulation.model';
import BankIcon from '../../../components/bankIcon/bankIcon.component';
import _ from 'lodash';
import { ProductType } from '../../../models/robot.model';
import ProductIcon from '../../../components/productIcon/bankIcon.component';

const columnHelper = createColumnHelper<Lead>();

function AvatarWithHoverZoom({name, photo} : { name: string, photo: string }) {
  const [isZoomed, setIsZoomed] = useState(false);

  return (
    <Box
      cursor={photo ? 'pointer' : null}
      onClick={() => setIsZoomed(true)}
      onMouseOut={() => setIsZoomed(false)}
    >
      <Avatar
        name={name}
        src={photo}
        borderRadius={photo && isZoomed ? 0 : '100%'}
        boxSize={photo && isZoomed ? "200px" : "40px"} // Ajuste o tamanho desejado
        transition="all 0.1s" // Adicione uma transição suave
      />
    </Box>
  );
}

export default function LeadsTable(props: {
  tableData: Array<Lead>,
  onSelect: (lead: Lead) => void,
  onPageChange: (page: number) => void,
  pagination: PaginatedMetaData,
  loading: boolean,
  onSelectRow: (rows: Array<string>) => void,
}) {
  const {tableData, onSelect, onPageChange, pagination, loading, onSelectRow} = props;
  const page = pagination.currentPage;
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const borderColor = useColorModeValue('gray.200', 'whiteAlpha.100');
  let defaultData = tableData || [];
  const itemsPerPage = pagination.perPage;
  const pages = Math.ceil(pagination.total / itemsPerPage);
  const [rowSelection, setRowSelection] = useState<{[key: string]: boolean}>({})
  const [data, setData] = useState([...defaultData]);

  useEffect(() => {
    setData(tableData);
  }, [tableData]);

  useEffect(() => {
    setRowSelection({});
  }, [data]);

  useEffect(() => {
    const selectedRows: Array<string> = [];
    for (const index in rowSelection) {
      selectedRows.push(tableData[+index].id);
    }
    onSelectRow(selectedRows);
  }, [rowSelection]);

  const columns = [
    columnHelper.accessor('id', {
      id: 'select',
      header: ({ table }) => (
        <Flex align='center'>
          <Link variant={'outline'} onClick={() => table.toggleAllRowsSelected()} >
          <Checkbox
            size={'lg'}
            isChecked={table.getIsAllRowsSelected()}
            colorScheme='brandScheme' me='10px' />
          </Link>
        </Flex>

      ),
      cell: ({ row, cell }) => (
        <Flex align='center'>
          <Checkbox
            size={'lg'}
            isChecked={row.getIsSelected()}
            disabled={!row.getCanSelect()}
            onChange={() => row.toggleSelected()}
            colorScheme='brandScheme' me='10px' />
        </Flex>
      ),
    }),
    columnHelper.accessor('photo', {
      id: 'photo',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{sm: '10px', lg: '12px'}}
          color="gray.400">
        </Text>
      ),
      cell: (info: any) => (
        <AvatarWithHoverZoom name={info.row.original.name} photo={info.getValue()} />
      ),
    }),
    columnHelper.accessor('name', {
      id: 'name',
      size: 300,
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{sm: '10px', lg: '12px'}}
          color="gray.400">
          NOME
        </Text>
      ),
      cell: (info: any) => (
        <Flex align="center">
          <Link onClick={() => onSelect(info.row.original as Lead)}>
            <Text color={textColor} fontSize="sm" fontWeight="700">
              {info.getValue()}
            </Text>
          </Link>
        </Flex>
      ),
    }),
    columnHelper.accessor('cpf', {
      id: 'cpf',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{sm: '10px', lg: '12px'}}
          color="gray.400">
          CPF
        </Text>
      ),
      cell: (info: any) => (
        <Flex align="center">
          <Text>
            {formatCPF(info.getValue())}
          </Text>
        </Flex>
      ),
    }),
    columnHelper.accessor('phone', {
      id: 'phone',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{sm: '10px', lg: '12px'}}
          color="gray.400">
          TELEFONE
        </Text>
      ),
      cell: (info: any) => (
        <Flex align="center">
          <Text>
            {formatPhoneNumber(info.getValue())}
          </Text>
        </Flex>
      ),
    }),
    columnHelper.accessor('product', {
      id: 'product',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{sm: '10px', lg: '12px'}}
          color="gray.400">
         Produto
        </Text>
      ),
      cell: (info: any) => (
        <Flex align='center'>
          <VStack>
            {_.orderBy(info.getValue(), 'bank').map((product: ProductType) =>
              <HStack spacing={5} alignContent={'space-between'}>
                <ProductIcon product={product} />
              </HStack>,
            )}
          </VStack>
        </Flex>
      ),
    }),
    columnHelper.accessor('lastSimulations', {
      id: 'lastSimulations',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{sm: '10px', lg: '12px'}}
          color="gray.400">
          Últimas Simulações
        </Text>
      ),
      cell: (info: any) => (
        <Flex align='center'>
          <VStack>
            {_.orderBy(info.getValue(), 'bank').map((simulation: LastSimulation) =>
              <HStack spacing={5} alignContent={'space-between'}>
                <BankIcon bank={simulation.bank} />
                <Tag
                  w='100px'
                  size={'sm'}
                  borderRadius='full'
                  variant='solid'
                  colorScheme={statusColor[simulation.status]}
                >
                  <TagLabel>{simulationStatusName[simulation.status]}</TagLabel>
                </Tag>
                {simulation.statusValidity ?
                  <Text w='130px'>Até {toDate(simulation.statusValidity)}</Text> :
                <Text w='130px'>Em {toDate(simulation.updatedAt)}</Text> }
                <Text w='80px' as={'b'}>{formatToCurrency(simulation.creditValue)}</Text>
              </HStack>,
            )}
          </VStack>
        </Flex>
      ),
    }),
    columnHelper.accessor('lastMessageStatus', {
      id: 'messageStatus',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{sm: '10px', lg: '12px'}}
          color="gray.400">
          Mensagem
        </Text>
      ),
      cell: (info: any) => (
        <Flex align="center">
          <Tag
            size={'sm'}
            borderRadius="full"
            variant="solid"
            colorScheme={statusColor[info.getValue() as LeadMessageStatus]}
          >
            <TagLabel>{messageStatusName[info.getValue() as LeadMessageStatus]}</TagLabel>
          </Tag>
        </Flex>
      ),
    }),
    columnHelper.accessor('leadTag', {
      id: 'leadTag',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{sm: '10px', lg: '12px'}}
          color="gray.400">
          Tag
        </Text>
      ),
      cell: (info: any) => (
        info.getValue() ? <VStack align="center">
          <Tag
            size={'sm'}
            variant="solid"
            colorScheme="teal"
          >
            <TagLabel>{info.getValue().name}</TagLabel>
          </Tag>
          { info.row.original.leadTagUpdatedAt ? (<Text textAlign={'center'} size={'sm'}>{toDate(info.row.original.leadTagUpdatedAt)}</Text>) : null }
        </VStack> : null
      ),
    }),
    columnHelper.accessor('lastMessageDate', {
      id: 'lastMessageDate',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{sm: '10px', lg: '12px'}}
          color="gray.400">
          Última mensagem
        </Text>
      ),
      cell: (info: any) => (
        <Flex align="center">
          {toDate(info.getValue())}
        </Flex>
      ),
    }),
    columnHelper.accessor('lastProposalStatus', {
      id: 'lastProposalStatus',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{sm: '10px', lg: '12px'}}
          color="gray.400">
          Proposta
        </Text>
      ),
      cell: (info: any) => (
        <Flex align="center">
          <Tag
            size={'sm'}
            borderRadius="full"
            variant="solid"
            colorScheme={statusColor[info.getValue() as LeadProposalStatus]}
          >
            <TagLabel>{proposalStatusName[info.getValue() as LeadProposalStatus]}</TagLabel>
          </Tag>
        </Flex>
      ),
    }),
  ];

  const table = useReactTable({
    enableMultiRowSelection: true,
    state: {
      rowSelection,
    },
    enableRowSelection: true, //enable row selection for all rows
    onRowSelectionChange: setRowSelection,
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    debugTable: false,
  });
  return (
    <Card flexDirection="column" w="100%" px="0px" overflowX={{sm: 'scroll', lg: 'hidden'}}>
      <Table variant="striped" color="gray.500" mb="24px" size='sm'>
        <Thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <Tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                return (
                  <Th
                    key={header.id}
                    colSpan={header.colSpan}
                    pe="10px"
                    borderColor={borderColor}
                    cursor="pointer"
                    onClick={header.column.getToggleSortingHandler()}>
                    <Flex
                      justifyContent="space-between"
                      align="center"
                      fontSize={{sm: '10px', lg: '12px'}}
                      color="gray.400">
                      {flexRender(header.column.columnDef.header, header.getContext())}{{
                      asc: '',
                      desc: '',
                    }[header.column.getIsSorted() as string] ?? null}
                    </Flex>
                  </Th>
                );
              })}
            </Tr>
          ))}
        </Thead>
        <Tbody>
          {table.getRowModel().rows.map((row) => {
            return (
              <Tr key={row.id}>
                {row.getVisibleCells().map((cell) => {
                  return (
                    <Td
                      key={cell.id}
                      fontSize={{sm: '14px'}}
                      minW={{sm: '50px', md: '100px', lg: 'auto'}}
                      borderColor="transparent">
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </Td>
                  );
                })}
              </Tr>
            );
          })}
        </Tbody>
      </Table>
      <HSeparator/>
      <Flex justifyContent={'space-between'} pr={10} pl={5} mt={5}>
        <Box>
          <Text>
            {pagination.next ? <span>{(page - 1) * pagination.perPage} a {(page) * pagination.perPage} de </span> : null}
            {pagination.total > 0 ? <b>{new Intl.NumberFormat('pt-BR').format(pagination.total)}</b> : 'Nenhum'}
          </Text>
        </Box>
        <Box>
          {!!loading && <CircularProgress color={'gray'} size={5} isIndeterminate/>}
          {page > 1 ?
            <Button
              onClick={() => onPageChange(page - 1)}
              leftIcon={<ArrowBackIcon/>}
              colorScheme="gray"
              variant="ghost">
              Anterior
            </Button> : null}
          {page < pages && pages > 1 ?
            <Button
              onClick={() => onPageChange(page + 1)}
              rightIcon={<ArrowForwardIcon/>}
              colorScheme="gray"
              variant="ghost">
              Próxima
            </Button> : null}
        </Box>
      </Flex>
    </Card>
  );
}
