import { format } from 'date-fns';

interface FBDate {
  seconds: number;
}

export function toDate(dateStr: any): string {
  if (dateStr) {
    const date = new Date(dateStr);
    return format(date, 'dd/MM/yyyy')
  } else {
    return '';
  }
}

export function formatToCurrency(value: number): string {
  const formatter = new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL",
  });
  return formatter.format(value);
}

export function formatPhoneNumber(phoneNumber: string): string {

  if (phoneNumber) {
    // Remova todos os caracteres não numéricos
    let cleanedPhoneNumber = phoneNumber.replace(/\D/g, "");
    // Verifique se o número tem pelo menos 10 dígitos
    if (cleanedPhoneNumber.length >= 10) {
      // Formate o número
      if (cleanedPhoneNumber.length === 10) {
        return cleanedPhoneNumber.replace(/(\d{2})(\d{4})(\d{4})/, "($1) $2-$3");
      } else if (cleanedPhoneNumber.length === 11) {
        return cleanedPhoneNumber.replace(/(\d{2})(\d{5})(\d{4})/, "($1) $2-$3");
      } else if (cleanedPhoneNumber.length === 12) {
        return cleanedPhoneNumber.replace(/(\d{2})(\d{2})(\d{4})(\d{4})/, "($2) $3-$4");
      } else if (cleanedPhoneNumber.length === 13) {
        return cleanedPhoneNumber.replace(/(\d{2})(\d{2})(\d{5})(\d{4})/, "($2) $3-$4");
      } else {
        return phoneNumber;
      }
    } else {
      return phoneNumber;
    }
  } else {
    return '';
  }
}


export function formatCPF(cpf: string): string {
  const cleanedCPF = cpf.replace(/\D/g, "");
  if (cleanedCPF.length !== 11) {
    return cpf;
  }
  return cleanedCPF.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
}

