import { addDays, differenceInDays, format, isValid, max, parse, subYears } from 'date-fns';

export function isCPFValid(cpf: string): string | null {

  if (!cpf) {
    return null;
  }
  // Remover caracteres especiais e espaços em branco do CPF
  cpf = cpf.replace(/\D/g, '');
  cpf = cpf.padStart(11, '0');

  // Verificar se o CPF possui 11 dígitos
  if (cpf.length !== 11) {
    return null;
  }

  // Verificar se todos os dígitos do CPF são iguais (CPF inválido)
  if (/^(\d)\1{10}$/.test(cpf)) {
    return null;
  }

  // Calcular o primeiro dígito verificador
  let sum = 0;
  for (let i = 0; i < 9; i++) {
    sum += parseInt(cpf.charAt(i)) * (10 - i);
  }
  let remainder = (sum * 10) % 11;
  if (remainder === 10 || remainder === 11) {
    remainder = 0;
  }
  if (remainder !== parseInt(cpf.charAt(9))) {
    return null;
  }

  // Calcular o segundo dígito verificador
  sum = 0;
  for (let i = 0; i < 10; i++) {
    sum += parseInt(cpf.charAt(i)) * (11 - i);
  }
  remainder = (sum * 10) % 11;
  if (remainder === 10 || remainder === 11) {
    remainder = 0;
  }
  if (remainder !== parseInt(cpf.charAt(10))) {
    return null;
  }

  // Se chegou até aqui, o CPF é válido
  return cpf;
}

export function isPhoneValid(phone: string): string | null {
  if (!phone) {
    return null;
  }
  phone = phone.replace(/\D/g, '');
  if(phone.length === 10){
    phone = phone.replace(/^(\d{2})(\d{8})$/, "$1" + "9" + "$2");
  }
  const test = /^(\d{11})$/.test(phone);
  if (test) {
    return phone;
  } else {
    return null;
  }
}

export function RgAleatorio(): string {
  // Gerar uma sequência de 8 dígitos aleatórios
  const numeros = Array.from({ length: 8 }, () => Math.floor(Math.random() * 10)).join('');

  // Calcular o dígito verificador
  const pesos = [2, 3, 4, 5, 6, 7, 8, 9];
  let soma = 0;

  for (let i = 0; i < 8; i++) {
    soma += parseInt(numeros[i]) * pesos[i];
  }

  const resto = soma % 11;
  const digitoVerificador = resto === 10 ? 'X' : resto.toString();

  // Retornar o RG completo sem hífen: "XXXXXXXXX"
  return `${numeros}${digitoVerificador}`;
}

export function extrairDigitoVerificador(contaComDigito: string): string | null {
  try {

    // Verificar se a conta está no formato esperado com hífen (XXXX-5)
    if (!contaComDigito) {
      return null;
    }

    // Verificar se a conta está no formato esperado com hífen (XXXX-5)
    if (!contaComDigito.includes('-')) {
     return null;
    }

    // Dividir a conta e o dígito usando o hífen como separador
    const [numeroConta, digitoVerificador] = contaComDigito.split('-');

    // Verificar se ambos são válidos (número e dígito)
    if (!numeroConta || !digitoVerificador) {
      return null;
    }

    // Verificar se o dígito é numérico ou 'X' (caso especial)
    if (!/^\d$|^X$/.test(digitoVerificador)) {
      return null;
    }

    // Retornar o dígito verificador
    return digitoVerificador;
  } catch (error) {
    return null; // Retornar null em caso de erro
  }
}

export function gerarEmailAleatorio(nomeCompleto: string): string {
  // Remover espaços extras, acentos e caracteres especiais
  const nomeNormalizado = nomeCompleto
    .trim()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .toLowerCase();

  // Dividir o nome em partes
  const partesNome = nomeNormalizado.split(' ').filter(Boolean); // Remove partes vazias

  // Se não houver nome ou sobrenome, usar 'usuario'
  const nome = partesNome[0] || 'usuario';
  const sobrenome = partesNome[1] || ''; // Se não houver sobrenome, manter em branco

  // Gerar um número aleatório de 1 a 9999
  const numeroAleatorio = Math.floor(Math.random() * 9999) + 1;

  // Escolher um domínio aleatório
  const dominios = ['gmail.com', 'outlook.com', 'yahoo.com', 'hotmail.com'];
  const dominioAleatorio = dominios[Math.floor(Math.random() * dominios.length)];

  // Montar o e-mail aleatório, ajustando caso não tenha sobrenome
  const email = sobrenome
    ? `${nome}.${sobrenome}${numeroAleatorio}@${dominioAleatorio}`
    : `${nome}${numeroAleatorio}@${dominioAleatorio}`;

  return email;
}

export function gerarDataAleatoria(dataString: string | null): string {
  const dezAnosAtras: Date = subYears(new Date(), 10);
  const dataHoje: Date = new Date();

  const regexData = /^\d{2}\/\d{2}\/\d{4}$/;
  if (!dataString || !regexData.test(dataString)) {
    return format(dataHoje, 'dd/MM/yyyy');
  }

  const dataInicial: Date = parse(dataString, 'dd/MM/yyyy', new Date());
  const dataInicialValida: Date = isValid(dataInicial) ? max([dataInicial, dezAnosAtras]) : dezAnosAtras;

  const diferencaDias: number = differenceInDays(dataHoje, dataInicialValida);
  const diasAleatorios: number = Math.floor(Math.random() * (diferencaDias + 1));

  const dataAleatoria: Date = addDays(dataInicialValida, diasAleatorios);
  return format(dataAleatoria, 'dd/MM/yyyy');
}

export function corrigirFormatoData(dataString: string): string {

  if (!dataString) {
    return '';
  }

  // Remove os zeros extras no dia e mês
  const regex = /^(\d{1,3})\/(\d{1,2})\/(\d{4})$/;

  const match = dataString.match(regex);
  if (!match) {
    return dataString; // Retorna a data original se não corresponder ao padrão
  }

  let dia = match[1];
  let mes = match[2];
  const ano = match[3];

  // Limita o dia a dois dígitos e o mês a dois dígitos
  dia = dia.padStart(2, '0').slice(-2); // Garante que o dia tenha dois dígitos
  mes = mes.padStart(2, '0'); // Garante que o mês tenha dois dígitos

  // Retorna a data corrigida no formato dd/MM/yyyy
  return `${dia}/${mes}/${ano}`;
}
