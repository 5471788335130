import { Alert, AlertDescription, AlertIcon, FormControl, HStack, Switch, Text } from '@chakra-ui/react';
import React from 'react';

interface IInputNumberComponent {
  error?: string;
  value: boolean;
  onChange: (value: boolean) => void;
  label?: string;
  disabled?: boolean;
  witdh?: number;
}

export function InputCheck(
  {
    error,
    onChange,
    value,
    label,
    disabled,
    witdh
  }: IInputNumberComponent) {
  return (
    <HStack alignItems={'center'}>
      {label ? <Text as={'b'} textAlign={'right'} w={200}>{label}</Text>: null}
      <FormControl isInvalid={!!error} maxW={300}>
        <Switch
          size='lg'
          isChecked={value}
          isDisabled={!!disabled}
          onChange={(e) => {
            onChange(e.target.checked)
          }}
        />
      </FormControl>
      {!!error && <Alert status='error' maxW={250}>
        <AlertIcon />
        <AlertDescription fontSize={'sm'}>{error}</AlertDescription>
      </Alert>}
    </HStack>
  );
}
